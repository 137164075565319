<template>
    <div class="carousel-container">
        <swiper class="swiper-container-android" :options="swiperOptions" :navigation="true" ref="mySwiper">
            <swiper-slide v-for="(page, i) in pages" :key="i">
                <img class="page-image" :src="page.img" alt="" />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev">
                <img :src="require('@/assets/icons/arrow_left.png')" alt="" width="24px" height="24px" />
            </div>
            <div class="swiper-button-next" slot="button-next">
                <img :src="require('@/assets/icons/arrow_right.png')" alt="" width="24px" height="24px" />
            </div>
        </swiper>
    </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
    name: 'ImageCarousel',
    props: ['pages', 'gender'],
    components: {
        swiper,
        swiperSlide,
    },
    computed: {
        swiperOptions() {
            return {
                initialSlide: 0,
                pagination: {
                    el: '.swiper-pagination',
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            }
        },
    },
}
</script>

<style scoped lang="scss">
.carousel-container {
    .swiper-slide {
        @include center;
        flex-direction: column;
        .page-image {
            width: 280px;
            height: auto;
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        background-image: none !important;
        font-size: 24px;
        @include center;
        i {
            color: black;
        }
    }
}
</style>
