<template>
    <div class="main-section">
        <img :src="require('@/assets/images/wm-promotion/main-section/5.png')" alt="" />
    </div>
</template>

<script>
export default {
    name: 'Wm1',
}
</script>

<style scoped lang="scss"></style>
