<template>
    <div class="promotion">
        <StackRouterHeaderBar :show-title="true" title="결혼서비스 런칭 기념이벤트" />
        <img :src="require('@/assets/images/wm-promotion/wm-1.png')" alt="" />
        <Wm1 />
        <Wm2 />
        <Wm3 />
        <Wm4 />
        <Wm5 />
        <Wm6 />
        <Wm7 />
        <Wm8 />
        <WmFaq />
        <BottomButton @click="clickBtn" label="무료 혜택받기" />
    </div>
</template>

<script>
import Wm1 from '@/routes/wm-promotion/components/main-section/Wm1'
import Wm2 from '@/routes/wm-promotion/components/main-section/Wm2'
import Wm3 from '@/routes/wm-promotion/components/main-section/Wm3'
import Wm4 from '@/routes/wm-promotion/components/main-section/Wm4'
import Wm5 from '@/routes/wm-promotion/components/main-section/Wm5'
import Wm6 from '@/routes/wm-promotion/components/main-section/Wm6'
import Wm7 from '@/routes/wm-promotion/components/main-section/Wm7'
import Wm8 from '@/routes/wm-promotion/components/main-section/Wm8'
import WmFaq from '@/routes/wm-promotion/components/main-section/WmFaq'
export default {
    name: 'WMPromotionPage',
    components: {
        Wm1,
        Wm2,
        Wm3,
        Wm4,
        Wm5,
        Wm6,
        Wm7,
        Wm8,
        WmFaq,
    },
    mounted() {
        this.$nativeBridge.postMessage({
            action: 'sendAirbridgeEvent',
            value: {
                category: 'enter_wouludmarryevent',
                option: {
                    value: this.$store.getters.me.id,
                },
            },
        })
    },
    methods: {
        clickBtn() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: 'click_vb_typeform',
                    option: {
                        value: this.$store.getters.me.id,
                    },
                },
            })
            const baseUrl =
                process.env.NODE_ENV === 'production'
                    ? 'https://wouldyoumarry.com/grade?'
                    : 'https://staging.skymarry.com/grade?'
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: baseUrl + `formId=qEjnLjoU&vbUserId=${this.$store.getters.me.id}`,
            })
        },
    },
}
</script>

<style lang="scss">
.promotion {
    img {
        width: 100%;
        height: auto;
    }
    .bottom-button {
        background: transparent !important;
        border-top: none !important;

        button {
            background: black !important;
        }
    }
}
</style>
