<template>
    <div class="container">
        <!--        <p class='text-center f-14 m-b-12' v-if='header'>우주메리 신규회원</p>-->
        <div class="profile-card">
            <div class="user-photo">
                <!-- <img :src="require(`@/assets/images/grade/${matchGender}/${user.photo}`)" alt="" height="24px"/> -->
                <img :src="require(`@/assets/images/wm-promotion/grade/${matchImage}`)" alt="" height="24px" />
            </div>
            <div class="user-info">
                <div class="age-height">
                    <span>{{ user.age }}세</span>
                    <span>{{ user.height }}cm</span>
                </div>
                <div class="job">
                    {{ user.job }}
                </div>
                <div class="income">
                    {{ user.income }}
                </div>
            </div>
        </div>
        <div class="footer" v-if="footer">
            <p>해당 프로필은 프로필 노출을 동의한 실제 회원분들로,</p>
            <p>캡처 및 도용을 금합니다.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfileCard',
    props: {
        header: {
            type: Boolean,
            default: false,
        },
        user: {
            type: Object,
        },
        gender: {
            type: String,
        },
        footer: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({}),
    computed: {
        matchGender() {
            return this.gender === 'male' ? 'female' : 'male'
        },
        matchImage() {
            if (this.user.photo === 'logo') {
                return 'logo.png'
            } else {
                return `${this.matchGender}/${this.user.photo}.jpg`
            }
        },
    },
}
</script>
<style scoped lang="scss">
.footer {
    //margin-left: 56px;
    font-size: 10px;
    margin-top: 8px;
    color: $grey-06;
}
.profile-card {
    width: 248px;
    height: 104px;
    background: #ffffff;
    border: 1px solid #f7f8f9;
    box-sizing: border-box;
    box-shadow: 2px 4px 8px rgba(208, 208, 208, 0.25);
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin: auto;

    .user-photo {
        width: 80px;
        height: 80px;
        margin: 0 12px;
        img {
            border: 1px solid #e9e9ed;
            // box-sizing: border-box;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .user-info {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #111111;
        .age-height {
            span:not(:last-child)::after {
                content: '\A';
                width: 4px;
                height: 4px;
                background-color: #b9bdc1;
                border-radius: 100px;
                display: inline-block;
                padding: auto;
                vertical-align: middle;
                white-space: nowrap;
                margin: 0 4px;
            }
        }
    }
}
</style>
