<template>
    <div class="main-section">
        <div class="reviews">
            <div class="title">
                <p>REVIEW</p>
                <div class="border" />
            </div>
            <ReviewCarousel :pages="pages" />
        </div>
    </div>
</template>

<script>
import ReviewCarousel from '@/routes/wm-promotion/components/ReviewCarousel'
export default {
    name: 'Wm1',
    components: { ReviewCarousel },
    computed: {
        pages() {
            return [
                {
                    img: require('@/assets/images/wm-promotion/image-carousel/_con1.png'),
                },
                {
                    img: require('@/assets/images/wm-promotion/image-carousel/_con2.png'),
                },
                {
                    img: require('@/assets/images/wm-promotion/image-carousel/_con3.png'),
                },
                {
                    img: require('@/assets/images/wm-promotion/image-carousel/_con4.png'),
                },
            ]
        },
    },
}
</script>

<style scoped lang="scss">
.reviews {
    padding: 40px 0 60px 0;
    max-width: 515px;

    .title {
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        @include center;
        flex-direction: column;

        .border {
            margin-top: 8px;
            border-bottom: 1px solid black;
            width: 25px;
            height: 1px;
        }
    }
}
</style>
