<template>
    <div class="main-section">
        <TabComponent
            :tab-items="tabItems"
            :between="true"
            :on-click-tab="onClickTab"
            :selected-tab="selectedTab"
            class="m-t-40"
        />
        <div class="graph-container">
            <GraphCarousel class="m-t-30" :pages="pages" />
            <ProfileCarousel
                class="m-t-26"
                :hide-pagination="true"
                :header="true"
                :footer="true"
                :profile="gradeProfile"
                :gender="selectedTab === 'male' ? 'female' : 'male'"
            />
        </div>
    </div>
</template>

<script>
import TabComponent from '@/routes/wm-promotion/components/TabComponent'
import GraphCarousel from '@/routes/wm-promotion/components/GraphCarousel'
import ProfileCarousel from '@/routes/wm-promotion/components/ProfileCarousel'
import gradeConstants from '@/assets/constants/promotion'
export default {
    name: 'Wm2',
    components: {
        TabComponent,
        GraphCarousel,
        ProfileCarousel,
    },
    data: () => ({
        selectedTab: 'male',
        tabItems: [
            {
                type: 'male',
                label: '남성회원',
            },
            {
                type: 'female',
                label: `여성회원`,
            },
        ],
    }),
    computed: {
        gradeProfile() {
            const gender = this.selectedTab === 'male' ? 'female' : 'male'
            // return gradeConstants.gradeProfile[gender].S
            if (gender === 'male') return gradeConstants.gradeProfile[gender].S
            else
                return [
                    gradeConstants.gradeProfile[gender].S[1],
                    gradeConstants.gradeProfile[gender].S[0],
                    gradeConstants.gradeProfile[gender]['A+'][0],
                ]
        },
        pages() {
            return this.selectedTab === 'male'
                ? [
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-male/male_income.png'),
                      },
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-male/male_asset.png'),
                      },
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-male/male_education.png'),
                      },
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-male/male_age.png'),
                      },
                  ]
                : [
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-female/female_appearance.png'),
                      },
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-female/female_income.png'),
                      },
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-female/female_education.png'),
                      },
                      {
                          img: require('@/assets/images/wm-promotion/image-carousel/graph-female/female_age.png'),
                      },
                  ]
        },
    },
    methods: {
        onClickTab(item) {
            item.selected = true

            this.tabItems.forEach(tab => {
                if (tab.type !== item.type) {
                    tab.selected = false
                }
            })

            this.selectedTab = item.type
        },
    },
}
</script>

<style scoped lang="scss">
.main-section {
    padding: 0 16px 60px 16px;
    .header {
        text-align: center;
        .title {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 12px;
        }
        .p {
            line-height: 22px;
            font-size: 14px;
        }
    }
    .graph-container {
        max-width: 515px;
    }
}
</style>
