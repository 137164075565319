export default {
    gradeComment: {
        male: {
            S: '외모, 학력, 스펙까지 완벽 그 자체',
            'A+': '외모, 학력, 스펙의 완벽한 삼박자',
            A: '외모와 스펙의 완벽한 조합',
            'A-': '안정된 경제력으로 완벽한 배우자감',
            B: '이상적인 배우자의 표준인',
        },
        female: {
            S: '스펙부터 외모까지 완벽 그 자체',
            'A+': '외모, 학력, 스펙의 완벽한 삼박자',
            A: '외모와 스펙의 완벽한 조합',
            'A-': '안정된 경제력으로 완벽한 배우자감',
            B: '이상적인 배우자의 표준인',
        },
    },
    gradeProfile: {
        male: {
            S: [
                {
                    photo: 10,
                    age: 33,
                    job: '프리랜서',
                    income: '7,000 만원',
                    height: 166,
                },
                {
                    photo: 5,
                    age: 31,
                    job: '모델출신 대기업',
                    income: '8,000만원',
                    height: 163,
                },
                {
                    photo: 4,
                    age: 28,
                    job: '공기업',
                    income: '8,000만원',
                    height: 167,
                },
            ],
            'A+': [
                {
                    photo: 6,
                    age: 31,
                    job: '외국계 대기업',
                    income: '8,000 만원',
                    height: 157,
                },
                {
                    photo: 7,
                    age: 33,
                    job: '대기업 연구원',
                    income: '7,600만원',
                    height: 164,
                },
                {
                    photo: 3,
                    age: 34,
                    job: '고등학교 교사',
                    income: '4,000만원',
                    height: 163,
                },
            ],
            A: [
                {
                    photo: 1,
                    age: 31,
                    job: '외국계 대기업',
                    income: '8,000 만원',
                    height: 157,
                },
                {
                    photo: 8,
                    age: 33,
                    job: '대기업 연구원',
                    income: '7,600만원',
                    height: 164,
                },
                {
                    photo: 'logo',
                    age: 36,
                    job: '수의사',
                    income: '8,000만원',
                    height: 164,
                },
            ],
            B: [
                {
                    photo: 9,
                    age: 28,
                    job: '학원강사',
                    income: '5,000 만원',
                    height: 163,
                },
                {
                    photo: 2,
                    age: 29,
                    job: '중소기업 회사원',
                    income: '4,700만원',
                    height: 165,
                },
                {
                    photo: 'logo',
                    age: 30,
                    job: '공무원',
                    income: '3,000만원',
                    height: 160,
                },
            ],
        },

        female: {
            S: [
                {
                    photo: 1,
                    age: 35,
                    job: '스타트업 대표이사',
                    income: '1억 7,000 만원',
                    height: 181,
                },
                {
                    photo: 3,
                    age: 35,
                    job: '성형외과 의사',
                    income: '2억',
                    height: 177,
                },
                {
                    photo: 'logo',
                    age: 38,
                    job: '대학교수',
                    income: '1억',
                    height: 175,
                },
            ],
            'A+': [
                {
                    photo: 5,
                    age: 32,
                    job: '대기업',
                    income: '9,100 만원',
                    height: 183,
                },
                {
                    photo: 4,
                    age: 36,
                    job: '약사',
                    income: '1억 1,00만원',
                    height: 176,
                },
                {
                    photo: 'logo',
                    age: 31,
                    job: '전문직 회계사',
                    income: '8,000만원',
                    height: 175,
                },
            ],
            A: [
                {
                    photo: 2,
                    age: 33,
                    job: 'IT개발자',
                    income: '6,000 만원',
                    height: 178,
                },
                {
                    photo: 6,
                    age: 34,
                    job: '중소기업 펀드운용',
                    income: '7,000만원',
                    height: 175,
                },
                {
                    photo: 'logo',
                    age: 34,
                    job: '대기업 연구원',
                    income: '1억 2,000만원',
                    height: 176,
                },
            ],
            B: [
                {
                    photo: 8,
                    age: 30,
                    job: '중소기업 직장인',
                    income: '5,000 만원',
                    height: 183,
                },
                {
                    photo: 7,
                    age: 32,
                    job: '공기업',
                    income: '5,000만원',
                    height: 180,
                },
                {
                    photo: 'logo',
                    age: 33,
                    job: '중소기업 직장인',
                    income: '4,700만원',
                    height: 177,
                },
            ],
        },
    },
}
