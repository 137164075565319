<template>
    <nav class="tabs">
        <div
            v-for="item in tabItems"
            :key="item.label"
            class="tab"
            :class="{ selected: item.type === selectedTab, primary: primary && item.type === selectedTab, between }"
            @click="onClickTab(item)"
        >
            <div class="content">
                <p class="label" v-html="$translate(item.label)" />
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'TabComponent',
    props: {
        tabItems: Array,
        between: {
            type: Boolean,
            default: false,
        },
        selectedTab: String,
        onClickTab: Function,
        primary: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        // selectedTab: 'spec',
    }),
    methods: {
        // onClickTab(item) {
        //     this.selectedTab = item.type
        // },
    },
}
</script>

<style scoped lang="scss">
.tabs {
    font-size: 16px;
    padding: 0 16px;
    display: flex;
    // width: 100%;
    height: 52px;
    color: $grey-04;
    border-bottom: 1px solid $grey-03;
    overflow-x: scroll;
    box-sizing: content-box;

    & > .tab {
        height: 52px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        padding: 0 16px;
        white-space: nowrap;
        cursor: pointer;

        &.between {
            flex: 1;
            @include center;
        }
        &.selected {
            color: black;
            border-bottom: 2px solid black;
        }
        &.primary {
            color: #06d3bb;
            border-bottom: 2px solid #06d3bb;
        }

        .content {
            position: relative;
        }
    }
    &::-webkit-scrollbar {
        display: none;
    }
}
</style>
