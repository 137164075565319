<template>
    <div class="container">
        <p class="text-center f-14 m-b-12" v-if="header">우주메리 신규회원</p>
        <div class="profile-carousel" :class="{ hidePagination }">
            <carousel
                class="carousel"
                :per-page="1"
                :pagination-position="header ? `none` : `bottom`"
                :pagination-size="profile.length"
                :pagination-active-color="'#FFFFFF'"
                :pagination-color="'#808080'"
                :navigateTo="[page, true]"
                :navigationEnabled="true"
                :navigationNextLabel="`<img class='nav-icn' src='${require('@/assets/icons/arrow_right.png')}' width='24px' height='24px'/>`"
                :navigationPrevLabel="`<img class='nav-icn' src='${require('@/assets/icons/arrow_left.png')}' width='24px' height='24px'/>`"
            >
                <slide v-for="(item, idx) in profile" :key="idx" class="slide">
                    <ProfileCard :header="header" :footer="footer" :user="item" :gender="gender" />
                </slide>
            </carousel>
        </div>
    </div>
</template>

<script>
// import { Carousel, Slide } from 'vue-carousel';
import ProfileCard from '@/routes/wm-promotion/components/ProfileCard'

export default {
    name: 'ProfileCarousel',
    components: { ProfileCard },
    props: {
        profile: {
            type: Array,
        },
        gender: {
            type: String,
        },
        footer: {
            type: Boolean,
            default: false,
        },
        header: {
            type: Boolean,
            default: false,
        },
        hidePagination: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        page: 0,
    }),
}
</script>
<style scoped lang="scss">
.profile-carousel {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    //width :100vw;
    // height: 200px;
    .carousel {
        width: 260px;
        display: inline-block;
    }
    .arrow-wrapper {
        height: 104px;
        padding: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.hidePagination {
        ::v-deep .VueCarousel-pagination {
            display: none !important;
        }
    }
}
::v-deep .VueCarousel-dot-container {
    margin: 24px !important;
    padding: 0 !important;
    height: 3px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}
::v-deep .VueCarousel-dot {
    display: initial;
    width: 24px !important;
    height: 3px !important;
    border-radius: 100px !important;
    background-color: #e9e9ed !important;
    opacity: 0.5 !important;
    margin-top: 0px !important;
    padding: 0 !important;
    margin: 0 2px !important;
}
::v-deep .VueCarousel-dot--active {
    display: initial;
    width: 24px !important;
    height: 3px !important;
    border-radius: 100px !important;
    background-color: #469b92 !important;
    padding: 0 !important;
    opacity: 1 !important;
}

::v-deep .VueCarousel-navigation-button {
    top: 52px !important;

    img {
        width: 24px !important;
        height: 24px !important;
    }
}
</style>
